import { gql } from "@apollo/client";


export const ImageFields = gql`
    fragment ImageFields on Image {
        altText
        height
        id
        url
        width
    }
`;