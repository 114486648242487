import { gql } from "@apollo/client";
import { MoneyV2Fields } from "./MoneyV2";
import { UserAddress } from "./UserAddress";
import { DeliveryAddress } from "./DeliveryAddress";
import { LineNode } from "./LineNode";

export const CartResponse = gql`
    ${MoneyV2Fields}
    ${UserAddress}
    ${DeliveryAddress}
    ${LineNode}
    
    fragment CartResponse on Cart {
      appliedGiftCards {
        amountUsed {
          ...MoneyV2Fields
        }
        balance {
          ...MoneyV2Fields
        }
        id
        lastCharacters
        presentmentAmountUsed {
          ...MoneyV2Fields
        }
      }
      attributes {
        key
        value
      }
      buyerIdentity {
        countryCode
        customer {
            acceptsMarketing
            addresses(first: 20) {
                edges {
                    cursor
                    node {
                        ...UserAddress
                    }
                }
                nodes {
                    ...UserAddress
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
            createdAt
            defaultAddress {
                ...UserAddress
            }
            displayName
            email
            firstName
            id
            lastName
            numberOfOrders
            # orders(first:100) {
            #     query
            # }
            phone
            tags
            updatedAt
        }
        email
        phone
        preferences {
            delivery {
                coordinates {
                    countryCode
                    latitude
                    longitude
                }
                deliveryMethod
                pickupHandle
            }
            wallet
        }
        purchasingCompany {
            company {
                createdAt
                externalId
                id
                name
                updatedAt
            }
            contact {
                createdAt
                id
                locale
                title
                updatedAt
            }
            location {
                createdAt
                externalId
                id
                locale
                name
                updatedAt
            }
        }
      }
      checkoutUrl
      cost {
        checkoutChargeAmount {
            ...MoneyV2Fields
        }
        subtotalAmount {
            ...MoneyV2Fields
        }
        subtotalAmountEstimated
        totalAmount {
            ...MoneyV2Fields
        }
        totalAmountEstimated
        totalDutyAmount {
            ...MoneyV2Fields
        }
        totalDutyAmountEstimated
        totalTaxAmount {
            ...MoneyV2Fields
        }
        totalTaxAmountEstimated
      }
      createdAt
      delivery {
        addresses {
            address {
                ...DeliveryAddress
            }
            id
            oneTimeUse
            selected
        }
      }
    #   deliveryGroups(first: 40) {
    #   }
      discountAllocations {
        discountApplication {
            allocationMethod
            targetSelection
            targetType
            value {
                ... on MoneyV2 {
                  ...MoneyV2Fields
                }
                ... on PricingPercentageValue {
                  percentage
                }
            }
        }
        discountedAmount {
            ...MoneyV2Fields
        }
        targetType
      }
      discountCodes {
        applicable
        code
      }
      id
      lines(first: 250) {
        edges {
          cursor
          node {
            ...LineNode
          }
        }
        # nodes {
        #   ...LineNode
        # }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
      note
      totalQuantity
      updatedAt
    }
`;