import { gql } from "@apollo/client";
import { MoneyV2Fields } from "./MoneyV2";
import { LineItemInfo } from "./LineItemInfo";


export const LineNode = gql`
    ${MoneyV2Fields}
    ${LineItemInfo}
    
    fragment LineNode on BaseCartLine {
        attributes {
            key
            value
        }
        cost {
            amountPerQuantity {
                ...MoneyV2Fields
            }
            compareAtAmountPerQuantity {
                ...MoneyV2Fields
            }
            subtotalAmount {
                ...MoneyV2Fields
            }
            totalAmount {
                ...MoneyV2Fields
            }
        }
        discountAllocations {
            discountApplication {
                allocationMethod
                targetSelection
                targetType
                value {
                    ... on MoneyV2 {
                        ...MoneyV2Fields
                    }
                    ... on PricingPercentageValue {
                        percentage
                    }
                }
            }
            discountedAmount {
                ...MoneyV2Fields
            }
            targetType
        }
        id
        merchandise {
            ...LineItemInfo
        }
        quantity
    }
`;