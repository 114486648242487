import storefront from '@shopify/storefront-api-client';
import fetch from "node-fetch";
import { GET_CART } from "../schemas/FetchCart";
import { cartToCheckout } from '../utils/convert';
import { CREATE_CART } from '../schemas/CreateCart';
import { ADD_LINE_ITEM } from '../schemas/AddLineItem';
import { UPDATE_LINE_ITEM } from '../schemas/UpdateLineItem';
import { REMOVE_LINE_ITEM } from '../schemas/RemoveLineItem';

global.fetch = fetch

const client = storefront.createStorefrontApiClient({
  storeDomain: 'https://checkout.merchforall.com/api/graphql',
  apiVersion: '2025-01',
  publicAccessToken: 'f249368e8e1c14b2ceecc8279fadb5c7',
});


async function fetch_cart(id) {
  const data = await client.request(GET_CART, {
    variables: { id }
  })
  return cartToCheckout(data.data.cart)
}

async function create() {
  const data = await client.request(CREATE_CART)
  const { cartCreate: { cart } } = data.data
  return cartToCheckout(cart)
}

async function addLineItems(checkoutId, lineItemsToAdd = []) {
  const result = await client.request(ADD_LINE_ITEM, {
    variables: {
      cartId: checkoutId,
      lines: lineItemsToAdd.map(item => ({
        quantity: item.quantity,
        merchandiseId: item.variantId
      }))
    }
  })
  const { cartLinesAdd: { cart } } = result.data
  return cartToCheckout(cart)
}

async function updateLineItems(checkoutId, lineItemsToUpdate = []) {
  const result = await client.request(UPDATE_LINE_ITEM, {
    variables: {
      cartId: checkoutId,
      lines: lineItemsToUpdate
    }
  })
  const { cartLinesUpdate: { cart } } = result.data
  return cartToCheckout(cart)
}

async function removeLineItems(checkoutId, lineItemsId) {
  const result = await client.request(REMOVE_LINE_ITEM, {
    variables: {
      cartId: checkoutId,
      lineIds: lineItemsId
    }
  })
  const { cartLinesRemove: { cart } } = result.data
  return cartToCheckout(cart)
}


export function clearCheckoutCookie(cookies) {
  const id = cookies.get('cart');
  if (id && id.includes("/Checkout/")) cookies.remove('cart', { path: '/' })
  console.log("cart::", cookies.get('cart'))
}


export const cart = {
  fetch: fetch_cart,
  create,
  addLineItems,
  updateLineItems,
  removeLineItems
}
