import { gql } from "@apollo/client";
import { MoneyV2Fields } from "./MoneyV2";
import { ImageFields } from "./Image";


export const LineItemInfo = gql`
    ${MoneyV2Fields}
    ${ImageFields}
    
    fragment LineItemInfo on ProductVariant {
        availableForSale
        barcode
        compareAtPrice {
            ...MoneyV2Fields
        }
        currentlyNotInStock
        id
        image {
            ...ImageFields
        }
        price {
            ...MoneyV2Fields
        }
        product {
            availableForSale
            compareAtPriceRange {
                maxVariantPrice {
                    ...MoneyV2Fields
                }
                minVariantPrice {
                    ...MoneyV2Fields
                }
            }
            createdAt
            description
            descriptionHtml
            featuredImage {
                ...ImageFields
            }
            handle
            id
            isGiftCard
            onlineStoreUrl
            productType
            publishedAt
            requiresSellingPlan
            tags
            title
            totalInventory
            trackingParameters
            updatedAt
            vendor
        }
        quantityAvailable
        quantityRule {
            increment
            minimum
            maximum
        }
        requiresShipping
        sku
        taxable
        title
        unitPrice {
            ...MoneyV2Fields
        }
        unitPriceMeasurement {
            measuredType
            quantityUnit
            quantityValue
            referenceUnit
            referenceValue
        }
        weight
        weightUnit
    }
`;