import { gql } from "@apollo/client";
import { print } from "graphql";
import { CartResponse } from "./fragments/CartResponse";

const GET_CART_GQL = gql`
    ${CartResponse}

    query GetCart($id: ID!) {
        cart(id: $id) {
            ...CartResponse
        }
    }
`;

export const GET_CART = print(GET_CART_GQL)