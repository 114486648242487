import { gql } from "@apollo/client";

export const DeliveryAddress = gql`
    fragment DeliveryAddress on CartDeliveryAddress {
        address1
        address2
        city
        company
        firstName
        formatted
        formattedArea
        lastName
        latitude
        longitude
        name
        phone
        provinceCode
        zip
    }
`;