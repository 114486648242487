import { gql } from "@apollo/client";
import { print } from "graphql";
import { CartResponse } from "./fragments/CartResponse";

const CREATE_CART_GQL = gql`
    ${CartResponse}

    mutation {
        cartCreate {
            cart {
                ...CartResponse
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const CREATE_CART = print(CREATE_CART_GQL)