export function cartToCheckout(cart) {
    if (!cart) return {
        completedAt: new Date()
    }
    return {
        id: cart.id,
        appliedGiftCards: cart.appliedGiftCards,
        buyerIdentity: cart.buyerIdentity,
        // completedAt: cart.completedAt,
        createdAt: cart.createdAt,
        currencyCode: cart.cost.totalAmount.countryCode,
        customAttributes: cart.attributes,
        // email: cart.email,
        lineItemsSubtotalPrice: cart.cost.subtotalAmount,
        note: cart.note,
        // order: null
        // orderStatusUrl: null,
        paymentDue: cart.cost.totalAmount,
        // ready: false,
        // requiresShipping: false,
        shippingAddress: cart.delivery.addresses,
        shippingDiscountAllocations: cart.discountAllocations.map(i => ({
            allocatedAmount: i.discountedAmount,
            discountApplication: i.discountApplication
        })),
        // shippingLine: null,
        subtotalPrice: cart.cost.subtotalAmount,
        // taxExempt: false,
        // taxesIncluded: false,
        totalDuties: cart.cost.totalDutyAmount,
        totalPrice: cart.cost.totalAmount,
        totalTax: cart.cost.totalTaxAmount,
        updatedAt: cart.updatedAt,
        webUrl: cart.checkoutUrl,
        discountApplications: cart.discountAllocations.map(i => i.discountApplication),
        lineItems: cart.lines.edges.map(({ node: line }) => ({
            id: line.id,
            customAttributes: line.attributes,
            discountAllocations: line.discountAllocations.map(i => ({
                allocatedAmount: i.discountedAmount,
                discountApplication: i.discountApplication
            })),
            quantity: line.quantity,
            title: line.merchandise.product.title,
            unitPrice: line.merchandise.unitPrice,
            variant: {
                id: line.merchandise.product.id,
                availableForSale: line.merchandise.availableForSale,
                barcode: line.merchandise.barcode,
                compareAtPrice: line.merchandise.compareAtPrice,
                currentlyNotInStock: line.merchandise.currentlyNotInStock,
                image: {
                    ...line.merchandise.image,
                    src: line.merchandise.image.url
                },
                price: line.merchandise.price,
                product: line.merchandise.product,
                quantityAvailable: line.merchandise.quantityAvailable,
                requiresShipping: line.merchandise.requiresShipping,
                selectedOptions: line.merchandise.selectedOptions,
                sku: line.merchandise.sku,
                title: line.merchandise.title,
                unitPrice: line.merchandise.unitPrice,
                unitPriceMeasurement: line.merchandise.unitPriceMeasurement,
                weight: line.merchandise.weight,
                weightUnit: line.merchandise.weightUnit,
                // sellingPlanAllocations: null,
                // storeAvailability: null
            }
        }))
    }
}