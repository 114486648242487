import { gql } from "@apollo/client";
import { print } from "graphql";
import { CartResponse } from "./fragments/CartResponse";

const ADD_LINE_ITEM_GQL = gql`
    ${CartResponse}

    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!){
        cartLinesAdd(cartId: $cartId, lines: $lines) {
            cart {
                ...CartResponse
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const ADD_LINE_ITEM = print(ADD_LINE_ITEM_GQL)