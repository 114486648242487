import { gql } from "@apollo/client";
import { print } from "graphql";
import { CartResponse } from "./fragments/CartResponse";

const UPDATE_LINE_ITEM_GQL = gql`
    ${CartResponse}

    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
            cart {
                ...CartResponse
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const UPDATE_LINE_ITEM = print(UPDATE_LINE_ITEM_GQL)